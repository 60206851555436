import React, { useEffect } from "react";
import { staticPage } from "../../config/staticPage";
import Layout from "../layout/Layout";
import CreateBulkOrders from "../components/account-bulk-orders/available-and-preorder-products/CreateBulkOrders";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";

const AvailableProducts = ({ location }) => {
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate(`/login?redirect_to=${location?.pathname}`);
    }
  }, []);

  if (!user) {
    return null;
  } else {
    return (
      <CreateBulkOrders
        location={location}
        path={"/preorder-products"}
        type={"preorder"}
      />
    );
  }
};

export const Head = () => {
  const login_nav = staticPage.find((obj) => obj.name === "preorder-products");
  return (
    <>
      <title>{login_nav?.title}</title>
      <meta name="description" content={login_nav?.description} />
    </>
  );
};

AvailableProducts.Layout = Layout;

export default AvailableProducts;
